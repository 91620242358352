import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import "./ProServices.css";
import NavigationBar from "../../components/NavBar/Navbar";
// import AboutForm from "../../components/AboutForm/AboutForm";
// import Newsletter from "../../components/Newsletter/Newsletter";
import Footer from "../../components/Footer/Footer";
import Cookie from "../../components/Cookie/Cookie";
import ProservicesJson from "./ProServices.json";

function Proservices() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const categoryParam = searchParams.get("category");

  const [filteredCategories, setFilteredCategories] = useState(ProservicesJson);

  useEffect(() => {
    if (categoryParam) {
      const selectedCategory = ProservicesJson.find(
        (category) => category.link === categoryParam
      );
      setFilteredCategories(selectedCategory ? [selectedCategory] : []);
    } else {
      setFilteredCategories(ProservicesJson);
    }
  }, [categoryParam]);

  // Section Descriptions
  const sectionDescriptions = {
    "Implementation Services":
      "Netskope's Implementation Services help businesses deploy and configure Netskope solutions efficiently. Whether migrating to Secure Access Service Edge (SASE) or optimizing Cloud Access Security Broker (CASB) policies, these services ensure smooth integration with existing IT environments. Experts provide step-by-step assistance, from initial setup to fine-tuning security policies, minimizing downtime and maximizing security posture.",
    "Managed Services":
      "Netskope Managed Services provide ongoing support and optimization for organizations that require continuous monitoring and maintenance. These services include proactive threat detection, performance tuning, policy updates, and expert troubleshooting. By leveraging Netskope’s Managed Services, organizations can offload the complexity of security management, ensuring consistent protection and compliance without burdening internal IT teams.",
    "Advisory Services":
      "Advisory Services offer strategic guidance to help businesses align their security architecture with industry best practices. Netskope’s security consultants assess existing security frameworks, identify gaps, and recommend tailored improvements. Whether developing Zero Trust Network Access (ZTNA) strategies or refining data protection policies, these services empower organizations to stay ahead of evolving cyber threats.",
  };

  return (
    <div>
      <Cookie />
      <NavigationBar />

      {/* Hero Section */}
      <section className="proservices-hero" id="proservices-hero"></section>

      {/* Deployment Packages Section */}
      <section className="proservices-body" id="proservices-body">
        <div className="container">
          {filteredCategories.length > 0 ? (
            filteredCategories.map((item, index) => (
              <div key={index}>
                {/* Display Section Titles with Description */}
                {item.sectionTitle && (
                  <div className="section-title-container">
                    <h2 className="section-title text-center">
                      {item.sectionTitle}
                    </h2>
                    {sectionDescriptions[item.sectionTitle] && (
                      <p className="section-description">{sectionDescriptions[item.sectionTitle]}</p>
                    )}
                  </div>
                )}

                {/* Display Categories with Packages */}
                {item.category && (
                  <div className="category-section">
                    <h3 className="category-title">{item.category}</h3>
                    {item.packages.length > 0 ? (
                      item.packages.map((pkg, i) => (
                        <div key={i} className="deployment-package mb-5">
                          <div className="package-content">
                            <h4 className="package-title">{pkg.title}</h4>
                            <p className="package-description">{pkg.shortDescription}</p>
                          </div>
                          <div className="package-action">
                            <a
                              href={`mailto:ps@fusionnode.ai?subject=Inquiry about ${pkg.title}`}
                              className="learn-more-btn"
                            >
                              Contact Us
                            </a>
                          </div>
                        </div>
                      ))
                    ) : (
                      <p className="text-muted">No packages available in this category.</p>
                    )}
                  </div>
                )}
              </div>
            ))
          ) : (
            <p className="text-center text-danger">No categories found.</p>
          )}
        </div>
      </section>

      {/* Footer Section */}
      {/* <AboutForm /> */}
      {/* <Newsletter /> */}
      <Footer />
    </div>
  );
}

export default Proservices;
