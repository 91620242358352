import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./WhyChoose.css";
import chooseData from "./WhyChoose.json"; // Import JSON file
import customIcon from "../../../assets/checkcircle.png"; // Import your custom icon

function WhyChoose() {
  const [choices] = useState(chooseData);

  return (
    <section className="why-choose-section">
      {/* TITLE */}
      <div className="title-container">
        <h1 className="why-choose-title">
          WHY CHOOSE <span className="fusionnode-text">FUSIONNODE?</span>
        </h1>
      </div>

      {/* CARDS GRID */}
      <div className={`why-choose-grid ${choices.length === 5 ? "five-items" : ""}`}>
        {choices.map((item, index) => (
          <div key={index} className="why-choose-card">
            <div>
              <img src={customIcon} alt="Custom Icon" className="custom-icon" />
              <h5 className="card-title-wc">{item.title}</h5>
            </div>
            <div>
              <p className="card-text-wc">{item.description}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}

export default WhyChoose;
