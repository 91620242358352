import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import DropdownMenus from "./Dropdownmenus";
import logo from "../../logo.png";
// import icon from "../../assets/assignment.png";
import { Link, useLocation } from "react-router-dom";
import "./Navbar.css";

function NavigationBar() {
  const location = useLocation();
  const [showDropdown, setShowDropdown] = useState(false);

  // Scroll to section when URL hash changes
  useEffect(() => {
    if (location.hash) {
      const sectionId = location.hash.replace("#", "");
      const element = document.getElementById(sectionId);
      if (element) {
        setTimeout(() => {
          element.scrollIntoView({ behavior: "smooth", block: "start" });
        }, 100);
      }
    }
  }, [location]);

  return (
    <Navbar expand="lg" className="navbar">
      <Container>
        <Navbar.Brand as={Link} to="/">
          <img src={logo} alt="logo" className="d-flex align-items-center" style={{ width: "10rem" }} />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link as={Link} to="/#main-body" className="text-light">
              HOME
            </Nav.Link>

            {/* Services Dropdown */}
            <DropdownMenus showDropdown={showDropdown} setShowDropdown={setShowDropdown} />

            <Nav.Link as={Link} to="/partners#partners-body" className="text-light">
              PARTNERS
            </Nav.Link>
            <Nav.Link as={Link} to="/product#product-body" className="text-light">
              PRODUCTS
            </Nav.Link>
            <Nav.Link as={Link} to="/about#about-body" className="text-light">
              ABOUT
            </Nav.Link>

            {/* Apply Now Button */}
            {/* <Nav.Link className="apply-now" as={Link} to="/apply#main-hero-apply">
              <span>
                APPLY NOW
              </span>
            </Nav.Link> */}
            <Nav.Link as={Link} to="/#contact-body" className="text-light">
              CONTACT US
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavigationBar;
