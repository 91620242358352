import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./MeshArchi.css";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import meshJson from "./MeshArchi.json";

function MeshArchi() {
  const [mesh] = useState(meshJson);

  return (
    <div>
      <section
        className="mesh-container" // Add my-4 for vertical margin
        style={{ background: "#EFF7FF" }}
      >
        {/* TITLE */}
        <div className="mesh-archi justify-content-center align-items-center">
          <h1 className="mesh-title text-dark fw-bold">
            REDIFINING CYBERSECURITY WITH{" "}
            <span style={{ color: "#0167db" }}> <br /> AI-POWERED MESH ARCHITECTURE</span>
          </h1>
          <p className="mesh-p fs-6 text-dark text-center">
            Step into the future of cybersecurity with FusionNode where we are revolutionizing protection through the power of mesh architecture. In todays's hyper connected world, traditional security measures fall short. That is why we have engineered a dynamic interconnected network of defense — an AI-powered cybersecurity mesh — to safeguard your business from every angle.
          </p>
        </div>
        {/* CARDS */}
        <Row className="mesh-row g-5">
          {mesh.map((mesh, i) => (
            <Col
              key={i}
              xs={12} // cards per row on extra small screens
              md={6} // cards per row on medium screens
              lg={4} // cards per row on large screens
              className="d-flex justify-content-center my-5"
            >
              <Card className="archi-card text-light d-flex flex-column">
                <Card.Body className="d-flex flex-column justify-content-start align-items-center">
                  <img
                    src={require(`../../../assets/MeshArchi/${mesh.imgicon}.webp`)}
                    alt="logo"
                    className="mesh-icon mb-4 mt-2"
                  />
                  <h5 className="archi-title text-dark mb-2">{mesh.title}</h5>
                  <p className="archi-p text-dark text-justify">
                    {mesh.info}
                  </p>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </section>
    </div>
  );
}

export default MeshArchi;
