import React from "react";
import "./Employer.css";
import Navbar from "../../components/NavBar/Navbar";
import WhoWeHelp from "../../components/Cards/WhoHelp/WhoweHelp";
import WhyChoose from "../../components/Cards/WhyChoose/WhyChoose";
import Footer from "../../components/Footer/Footer";
import Cookie from "../../components/Cookie/Cookie";
import howitworks from "../../assets/howitworks.png";

const EmployerOR = () => (
    <div>
      <Cookie />
      <Navbar />

      <section className="employer-body" id="employer-body"></section>

      <div className="text-container">
        <h1 className="main-title-eor">
          SECURE, COMPLIANT, AND SCALABLE OFFSHORE WORKFORCE SOLUTIONS — BUILT FOR GROWTH
        </h1>
        <h2 className="sub-title">
          Seamless Expansion. <span className="highlight">Zero Compliance Headaches.</span> Top Talent.
        </h2>
      </div>

      <div className="description-box"> 
        <p>
          <span className="company-name">FusionNode</span> helps cybersecurity, data analytics, and engineering companies expand into the Philippines—without the risk, complexity, or overhead of setting up a local entity. Our end-to-end Employer of Record (EOR) services handle HR, payroll, compliance, and workforce management, so you can scale faster and smarter.
        </p>
      </div>

      <p className="footer-text">
        Need a compliant, high-performing remote team? We make it happen.
      </p>

      <WhoWeHelp />

      {/* How it Works Section */}
      <div className="title-container">
        <h1 className="how-it-works">HOW IT WORKS</h1>
        <img src={howitworks} alt="How it works" className="your-image-class" />
      </div>

      <WhyChoose />

      <div className="text-container">
        <h1 className="sub-title-foot">
          Ready to scale your business with the right talent—compliantly and efficiently?
        </h1>
        <h2 className="main-title-foot">
          <a href="mailto:ps@fusionnode.ai" className="email-link">
            GET STARTED NOW
          </a>
        </h2>
      </div>


      <Footer />
    </div>
);

export default EmployerOR;
