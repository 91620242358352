import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import About from "./pages/About/About";
import Partners from "./pages/Partners/Partners"
import Services from './pages/Services/ServicesPages';
import Proservices from './pages/ProfessionalServices/ProServices'
import EmployerOR from './pages/Eor/Employer';
import Product from './pages/Product/Product';
import HowItWorks from "./pages/How/How";
import Error from "./pages/Error/Error";
import Apply from "./pages/ApplyNow/Apply";
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Privacy from './pages/PrivacyPolicy/Privacy';
import JobDescription from './pages/JobDescription/JobDesc';

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/about",
    element: <About />,
  },
  {
    path: "/partners",
    element: <Partners />,
  },
  {
    path: "/services",
    element: <Services />
  },
  {
    path: "/proservices",
    element: <Proservices/>
  },
  {
    path: "/employer",
    element: <EmployerOR />
  },
  {
    path: "/product",
    element: <Product />
  },
  {
    path: "/howItWorks",
    element: <HowItWorks />
  },
  {
    path: "/privacy",
    element: <Privacy/>
  },
  {
    path: "/error",
    element: <Error/>
  },
  {
    path: "/apply",
    element: <Apply/>
  },
  {
    path: "/jobDescription",
    element: <JobDescription/>
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <RouterProvider router={router}/>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
