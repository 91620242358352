import React, { useState, useEffect } from "react";
import "./JobDesc.css";
import "bootstrap/dist/css/bootstrap.min.css";
import NavigationBar from "../../components/NavBar/Navbar";
import AboutForm from "../../components/AboutForm/AboutForm";
import Subs from "../../components/Newsletter/Newsletter";
import Footer from "../../components/Footer/Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuildingUser, faListCheck, faUserTie, faCalendarCheck, faUserShield, faNetworkWired } from "@fortawesome/free-solid-svg-icons";
import applyJson from "../ApplyNow/Apply.json";
import { useNavigate, useLocation } from "react-router-dom";

function JobDescription() {
    const navigate = useNavigate();
    const location = useLocation();
    const [selectedJob, setSelectedJob] = useState(null);
    const [loading, setLoading] = useState(true);

    // Handle hash navigation
    useEffect(() => {
        const sectionId = location.hash.slice(1);
        if (sectionId) {
            const element = document.getElementById(sectionId);
            if (element) {
                element.scrollIntoView({ behavior: "smooth" });
            }
        }
    }, [location.hash]);

    // Fetch job data based on query parameter `id`
    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const jobId = queryParams.get("id");

        if (!jobId) {
            navigate("/error", { replace: true });
            return;
        }

        const foundJob = applyJson.find((job) => job.id.toString() === jobId);

        if (foundJob) {
            setSelectedJob(foundJob);
        } else {
            navigate("/error", { replace: true });
        }

        setLoading(false);
    }, [location.search, navigate]);

    const iconMap = {
        faBuildingUser,
        faListCheck,
        faUserTie,
        faCalendarCheck,
        faUserShield,
        faNetworkWired,
    };

    if (loading) {
        return (
            <div className="loading-container">
                <p>Loading job details...</p>
            </div>
        );
    }

    return (
        <>
            <NavigationBar />
            <div id="main-hex-bg">
                <div className="main-job-container">
                    <div className="jobDescription-container">
                        {selectedJob ? (
                            <>
                                <section id="overview" className="titles">
                                    <h2 className="mb-0 fw-bold">{selectedJob.title}</h2>
                                    <p>Date Posted: {selectedJob.date || "N/A"}</p>
                                    <div className="categories mx-1">
                                        <div className="category-list">
                                            {selectedJob.category?.map((cat, index) => (
                                                <div key={index} className="category mb-2">
                                                    <FontAwesomeIcon
                                                        icon={iconMap[cat.icon] || faListCheck}
                                                        className="mx-1"
                                                        color="#0167DB"
                                                    />
                                                    <span>{cat.label}</span>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </section>
                                <section id="responsibilities" className="responsibilities mx-1 mt-4">
                                    <h5 className="fw-bold">Responsibilities</h5>
                                    {selectedJob.responsibilities?.length ? (
                                        selectedJob.responsibilities.map((respGroup, groupIndex) => (
                                            <div key={groupIndex} className="responsibility-group">
                                                <h6 className="fw-bold">{respGroup[0]}</h6>
                                                <ul>
                                                    {respGroup.slice(1).map((item, itemIndex) => (
                                                        <li key={itemIndex}>{item}</li>
                                                    ))}
                                                </ul>
                                            </div>
                                        ))
                                    ) : (
                                        <p>No responsibilities listed.</p>
                                    )}
                                </section>
                                <section id="requirements" className="requirements mx-1 mt-4">
                                    <h5 className="fw-bold">Requirements</h5>
                                    <ul>
                                        {selectedJob.requirements?.length ? (
                                            selectedJob.requirements.map((item, index) => (
                                                <li key={index}>{item}</li>
                                            ))
                                        ) : (
                                            <p>No requirements listed.</p>
                                        )}
                                    </ul>
                                </section>
                                <section id="qualifications" className="qualifications mx-1 mt-4">
                                    <h5 className="fw-bold">Preferred Qualifications</h5>
                                    <ul>
                                        {selectedJob.qualifications?.length ? (
                                            selectedJob.qualifications.map((item, index) => (
                                                <li key={index}>{item}</li>
                                            ))
                                        ) : (
                                            <p>No qualifications listed.</p>
                                        )}
                                    </ul>
                                </section>
                                <section className="d-flex justify-content-start align-items-start mt-4">
                                    <a href={selectedJob.formLink} className="text-decoration-none">
                                        <button className="apply-btn mt-1">APPLY</button>
                                    </a>
                                </section>
                            </>
                        ) : (
                            <p>Job not found</p>
                        )}
                    </div>
                </div>
            </div>
            <AboutForm />
            <Subs />
            <Footer />
        </>
    );
}

export default JobDescription;
