import React, { useState, useEffect } from "react";
import Productarchi from "../../assets/architecture.webp";
import Productproc from "../../assets/process.webp";
import Productprocmobile from "../../assets/processmobile.webp";
import Productarchimobile from "../../assets/architecture.webp";
import NavigationBar from "../../components/NavBar/Navbar";
// import AboutForm from "../../components/AboutForm/AboutForm";
// import Newsletter from "../../components/Newsletter/Newsletter";
import Footer from "../../components/Footer/Footer";
import MeshArchi from "../../components/Cards/MeshArchi/MeshArchi"
import "./Product.css";


const Product = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <NavigationBar />
      <section className="product-body" id="product-body"></section>
      <div className="product-container">
        <section className="fusion-section">
          <h2>
            <span className="blue-text">SENTRY</span> CTEM AS A SERVICE
          </h2>
          <p className="section-description">
            Stay ahead of cyber threats with our AI-driven Continuous Threat Exposure Management (CTEM) security solution. The Sentry platform integrates proactive threat hunting, attack surface monitoring, and real-time intelligence from the dark & deep web. With AI & ML modeling, FusionCenter SOC analysts, and Azure security tools, we provide comprehensive protection for your data and systems. Gain actionable insights through our customer dashboard and fortify your cybersecurity defenses today.
          </p>
          <div className="image-container">
            <img
              src={isMobile ? Productarchimobile : Productarchi}
              alt="Fusion Center Architecture"
              className="responsive-image1"
            />
          </div>
        </section>

        <section className="fusion-section">
          <h2>
            <span className="blue-text">SENTRY</span> PLATFORM
          </h2>
          <p className="section-description">
            Enhance your cybersecurity posture with our AI-powered SIEM solution. Our platform seamlessly collects data from multiple sources, analyzes threats in real time, automates workflows, and enables proactive threat hunting. The customer dashboard provides visual insights, while our Security Operations Team ensures rapid incident response and mitigation. Stay ahead of cyber threats with next-gen security intelligence.
          </p>
          <div className="image-container1">
            <img
              src={isMobile ? Productprocmobile : Productproc}
              alt="Fusion Center Process"
              className="responsive-image"
            />
          </div>
        </section>
        <MeshArchi />
      </div>
      
      {/* <AboutForm /> */}
      {/* <Newsletter /> */}
      <Footer />
    </>
  );
};

export default Product;
