import React, { useState } from "react";
import Swal from "sweetalert2";
import "./ServicesPages.css";
import NavigationBar from "../../components/NavBar/Navbar";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
// import AboutForm from "../../components/AboutForm/AboutForm";
// import Newsletter from "../../components/Newsletter/Newsletter";
// import Footer from "../../components/Footer/Footer";
import Cookie from "../../components/Cookie/Cookie";
import serviceJson from "./Service.json";
import { renderToStaticMarkup } from 'react-dom/server';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloud, faShieldHalved, faUserSecret, faBuilding, faPersonChalkboard, faUsers } from "@fortawesome/free-solid-svg-icons";

function ServicesPages() {
  const [service] = useState(serviceJson);

  const iconMap = {
    faCloud,
    faShieldHalved,
    faUserSecret,
    faBuilding,
    faPersonChalkboard,
    faUsers
  };


  const handleLearnMore = (service) => {
    // Dynamically fetch the correct icon from iconMapPopup
    const IconComponent = iconMap[service.imgicon];
    const iconMarkup = renderToStaticMarkup(<FontAwesomeIcon icon={IconComponent} className="fs-3" style={{color: '#0167DB' }} />);
  
    Swal.fire({
      html: `
        <div style="text-align: center;">
          <div class="services-icon mb-2" style="background-color: #fff">
            ${iconMarkup} <!-- Injecting the JSX icon here -->
          </div>
          <p style="font-weight: bold; text-align: start;">${service.title}</p>
          <p>${service.more}</p>
        </div>
      `,
      showConfirmButton: false,
      showCloseButton: true,
      closeButtonHtml: '&times;',
      customClass: {
        popup: 'service-popup',
        closeButton: 'swal-close-button'
      }
    });
  };

  return (
    <div>
      <Cookie />
      <NavigationBar />
      <section className="service-body" id="service-body">
      </section>

      <section
        className="d-flex flex-column align-items-center justify-content-center"
        style={{ background: "#fff" }}
      >
        <div className="container align-items-center">
          <Row className="gx-0 g-5 mt-1 mb-5 justify-content-center align-items-center">
            {service.map((service, i) => (
              <Col
                key={i}
                xs={12}
                md={6}
                lg={4}
                className="d-flex justify-content-center align-items-center"
              >
                <Card className="cardServices text-dark p-3 mx-sm-auto mx-md-auto" onClick={() => handleLearnMore(service)}>
                  <div className="mx-3">
                    <section>
                      <div className="services-icon mb-2">
                        <FontAwesomeIcon icon={iconMap[service.imgicon]} alt="Services" className="fs-3" />
                      </div>
                      <p className="fw-bold text-start">{service.title}</p>
                    </section>
                    <section>
                      <p className="service-info text-start">{service.info}</p>
                    </section>
                    <section className="d-flex justify-content-end">
                      <p className="learn-more" >Learn More ...</p>
                    </section>
                  </div>
                </Card>
              </Col>
            ))}
          </Row>
        </div>
      </section>

      {/* <AboutForm />
      <Newsletter /> */}
      {/* <Footer /> */}
    </div>
  );
}

export default ServicesPages;
