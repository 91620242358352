import React from "react";
import "./Partners.css";
import NavigationBar from "../../components/NavBar/Navbar";
import Educational from "../../components/Cards/Educational/Educational";
import Partnered from "../../components/Cards/PartneredServices/Partnered";
// import AboutForm from "../../components/AboutForm/AboutForm";
import Footer from "../../components/Footer/Footer";
// import Newsletter from "../../components/Newsletter/Newsletter"
import Cookie from "../../components/Cookie/Cookie";

const About = () => (
  <div>
    <Cookie />
    <NavigationBar />
    <section className="partners-main-body" id="partners-body">
      
    </section>
    <div className="hex-bg mt-0">
    <Educational />
    <Partnered />
    </div>
    {/* <AboutForm /> */}
    {/* <Newsletter /> */}
    <Footer />
  </div>
);

export default About;
