import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./WhoweHelp.css";
import helpData from "./WhoweHelp.json"; // Import JSON data
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// Import icons directly
import mspIcon from "../../../assets/msp-icon.png";
import enterpriseIcon from "../../../assets/enterprise-icon.png";
import oemIcon from "../../../assets/oem-icon.png";
import startupIcon from "../../../assets/startup-icon.png";

// Map icon keys to imported images
const iconsMap = {
  "msp-icon": mspIcon,
  "enterprise-icon": enterpriseIcon,
  "oem-icon": oemIcon,
  "startup-icon": startupIcon,
};

// WhoWeHelpCard Component
function WhoWeHelpCard() {
  return (
    <section className="who-we-help-section d-flex flex-column align-items-center justify-content-center">
      {/* TITLE */}
      <div className="text-center text-primary">
        <h2 className="fw-bold">
          WHO <span style={{ color: "#0066cc" }}>WE HELP</span>
        </h2>
      </div>

      {/* CARDS */}
      <Row className="who-we-help-row g-4">
        {helpData && helpData.length > 0 ? (
          helpData.map((item, index) => (
            <Col key={index} xs={12} md={6} lg={3} className="d-flex justify-content-center">
              <Card className="who-we-help-card border-light text-dark text-center h-100">
                <Card.Body className="d-flex flex-column align-items-center h-100">

                  {/* ICON IMAGE */}
                  <div>
                    <img src={iconsMap[item.icon]} alt={item.title} className="who-we-help-icon" />

                    {/* TITLE */}
                    <h5 className="card-title fw-bold">{item.title}</h5>
                  </div>

                  {/* BULLET LIST DESCRIPTION */}
                  <div>
                    <ul className="card-text">
                      {item.description.map((desc, i) => (
                        <li key={i}>{desc}</li>
                  
                    ))}
                  </ul>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          ))
        ) : (
          <p className="text-muted">No data available</p>
        )}
      </Row>
    </section>
  );
}

export default WhoWeHelpCard;
