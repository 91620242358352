import React from "react";
import { Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import "./Dropdowns.css";

function DropdownMenus({ showDropdown, setShowDropdown }) {
  return (
    <Dropdown
      onMouseEnter={() => setShowDropdown(true)}
      onMouseLeave={() => setShowDropdown(false)}
      show={showDropdown}
    >
      <Dropdown.Toggle as="div" className="nav-link text-light">
        SERVICES
      </Dropdown.Toggle>
      <Dropdown.Menu className="main-dropdown-menu">
        <Dropdown.Item as={Link} to="/#services-body" className="text-light">
          Our Services
        </Dropdown.Item>
        <Dropdown.Item as={Link} to="/employer#employer-body" className="text-light">
          Employer of Record
        </Dropdown.Item>
        <Dropdown.Item as={Link} to="/proservices#proservices-hero" className="text-light">
          Netskope Professional Services
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default DropdownMenus;
