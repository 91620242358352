import React, { useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import Navbar from "./components/NavBar/Navbar";
import Services from "./components/Cards/Services/Services";
// import Services from "./pages/Services/ServicesPages";
import Partnerships from "./components/Cards/Partnerships/Partnerships";
// import Newsletter from "./components/Newsletter/Newsletter";
import logo from "./assets/logo-hero.png";
import AboutForm from "./components/AboutForm/AboutForm";
import Footer from "./components/Footer/Footer";
import Cookie from "./components/Cookie/Cookie"

function App() {
  // Create a ref to the AboutForm section
  const aboutFormRef = useRef(null);

  // Function to scroll to the AboutForm
  // const handleContactUsClick = () => {
  //   aboutFormRef.current?.scrollIntoView({ behavior: "smooth" });
  // };
  return (
    <div className="App">
      <Cookie />
      <Navbar /> {/*Navigation Bar*/}
      <section className="main-body" id="main-body">
        
        <div className="hero-margin text-start hero-lg">
          <div>
            <img src={logo} alt="hero-logo" className="hero-logo"></img>
          </div>
          <div className="hero-sub">
            <h5 className="tagline fw-bold pt-2">
              "Where <span style={{ color:"#0167DB" }}>Innovation, Technology,</span> and{" "}
              <span style={{ color:"#0167DB" }}>Talent</span> are <br /> fused to exponentially
              scale the Cyber challenges."
            </h5>
            {/* <button
              className="connect-button"
              href=""
              onClick={handleContactUsClick}
            >
              Contact Us
            </button> */}
          </div>
        </div>

        <div className="hero-margin text-center hero-md">
          <div>
            <img src={logo} alt="hero-logo" className="hero-logo"></img>
          </div>
          <div className="hero-sub">
          <h5 className="tagline fw-bold pt-2">
              "Where <span style={{ color:"#0167DB" }}>Innovation, Technology,</span> and{" "}
              <span style={{ color:"#0167DB" }}>Talent</span> are <br /> fused to exponentially
              scale the Cyber challenges."
            </h5>
            {/* <button
              className="connect-button"
              href=""
              onClick={handleContactUsClick}
            >
              Contact Us
            </button> */}
          </div>
        </div>

        <div className="hero-margin text-center hero-sm">
          <div>
            <img src={logo} alt="hero-logo" className="hero-logo"></img>
          </div>
          <div className="hero-sub">
            <h5 className="tagline fw-bold pt-2">
              "Where <span style={{ color:"#0167DB" }}>Innovation, Technology,</span> and{" "}
              <span style={{ color:"#0167DB" }}>Talent</span> are <br /> fused to exponentially
              scale the Cyber challenges."
            </h5>
            {/* <button
              className="connect-button"
              href=""
              onClick={handleContactUsClick}
            >
              Contact Us
            </button> */}
          </div>
        </div>
        
      </section>
      
      <section className="info-section text-center d-flex flex-column">
        <p className="info-p fs-6 fs-md-5 fs-sm-5 text-center fw-bold my-4">
          Your Trusted Security Operations Partner in a World of AI and Digital
          Technology
        </p>
        <p className="info-p fs-6 fs-md-5 fs-sm-5 text-center fw-bold mb-5">
          FusionNode understands that in today's interconnected world,
          cybersecurity is not just a necessity - it is a cornerstone of
          success. As your trusted Managed Service Provider (MSP), we
          are here to be your vigilant guardian, standing between your business
          and the ever-evolving threats of the digital landscape.
        </p>
      </section>
      <Partnerships /> 

      <div ref={aboutFormRef} className="services-body" id="services-body">
      <Services /> 
      </div>

      <div ref={aboutFormRef} className="contact-body" id="contact-body">
        <AboutForm />
      </div>
      {/* <Newsletter /> */}
      <Footer />
    </div>
  );
}

export default App;
