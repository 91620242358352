import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Partnered.css";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Swal from "sweetalert2";
import partneredJson from "./Partnered.json"

function Educational() {
  const [partnered] = useState(partneredJson);

  const handleLearnMore = (partnered) => {
    // Create the HTML for list items
    const moreItemsHTML = partnered.more
    .map((item) => `<li style="text-align: justify;">${item}</li>`)
      .join("");
  
    Swal.fire({
      html: ` 
        <div style="text-align: center;">
          <div class="mb-2 partnered-imgbg" style="display: inline-flex; align-items: center; justify-content: center;">
            <img src="${require(`../../../assets/Partners/${partnered.imgicon}.webp`)}" alt="Partnered" class="partnered-img"/>
          </div>
          <h3 style="font-weight: bold; margin-top: 10px;">${partnered.title}</h3>
          <ul class="educ-p text-dark fs-6 text-start" style="text-align: justify;">
            ${moreItemsHTML}
          </ul>
        </div>
      `,
      showConfirmButton: false,
      showCloseButton: true,
      closeButtonHtml: '&times;',
      customClass: {
        popup: 'partnered-popup',
        closeButton: 'partnered-swal-close-button'
      }
    });
  };
  return (
    <div>
      <section
        className="d-flex flex-column align-items-center justify-content-center" // Add my-4 for vertical margin
      >
        {/* CARDS */}
        <div className="container-fluid">
        <section className="part-sections">
          <h1 className="fw-bold ms-5 mt-5 mb-4">
            TECHNOLOGY PARTNERS
          </h1>
        </section>
          <Row className="g-5 d-flex justify-content-start align-items-center mb-5 my-1 mx-3">
            {partnered.map((partnered, i) => (
              <Col key={i} xs={12} md={6} lg={4} className="d-flex justify-content-center align-content-center my-2">
                <Card className="partneredCards">
                  <div className="mx-3 d-flex flex-row">
                  <div className="d-flex mt-3">
                    <div className="partnered-imgbg me-3">
                      <img src={require(`../../../assets/Partners/${partnered.imgicon}.webp`)} alt="Educational" className="partnered-img"/>
                    </div>
                  </div>
                  <div className="my-3">
                    <p className="fw-bold mb-1">{partnered.title}</p>
                    <p className="partnered-para text-start mb-1">{partnered.info}</p>
                    <button className="learnmore-btn w-50" onClick={() => handleLearnMore(partnered)}>Learn More</button>
                  </div>
                  </div>
                </Card>
              </Col>
            ))}
          </Row>
        </div>

      </section>
    </div>
  );
}

export default Educational;
