import React, { useState } from "react";
import "./About.css";
import NavigationBar from "../../components/NavBar/Navbar";
import AboutForm from "../../components/AboutForm/AboutForm";
// import Newsletter from "../../components/Newsletter/Newsletter";
import Cookie from "../../components/Cookie/Cookie";
import Footer from "../../components/Footer/Footer";
import { Card, Row, Col } from "react-bootstrap";
import Jerry from "../../assets/Team/jerry.webp";
import aboutJson from "./About.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";


function About() {
  const [usTeam] = useState(aboutJson.usTeamGroup);
  const [phTeam] = useState(aboutJson.phTeamGroup);
  const [boardAdvisors] = useState(aboutJson.boardAdvisorGroup);

  return (
    <div>
      <Cookie />
      <NavigationBar />
      <section className="about-hero-body" id="about-body">

      </section>
      <section className="info-sec">
        <p className="aboutTeam-p fw-bold text-center">
          FusionNode understands that in today's interconnected world,
          cybersecurity is not just a necessity—it is a cornerstone of success.
          As your trusted Security Partner, we are here to be your vigilant
          guardian standing between your business and the ever-evolving threats
          of the digital landscape.​
        </p>
      </section>
      <div>
        <section className="ceo-section d-flex flex-column justify-content-center align-items-center">
          <h1 className="text-light fw-bold mt-5">
            MEET <span style={{ color: "#92C9FF" }}>THE TEAM</span>
          </h1>
          <Card className="team-card mt-5">
            <img src={Jerry} alt="CEO" className="team-img mt-5" />
            <section className="d-flex flex-row justify-content-between align-items-center w-100 mt-4 mb-5 text-light text-start px-4 gap-1">
              <div>
                <h6 className="fw-normal mb-0">JERRY STO. TOMAS</h6>
                <p className="mt-0 mb-0">CEO/Founder</p>
              </div>
              <a href="https://www.linkedin.com/in/jstotomas/" className="text-light">
                <FontAwesomeIcon icon={faLinkedin} className="fs-1" />
              </a>
            </section>
          </Card>
        </section>

        <section className="ff-positions d-flex flex-column justify-content-center align-items-center">
          <h1 className="text-light fw-bold mb-5 my-5">
            US TEAM <span style={{ color: "#92C9FF" }}>MEMBERS</span>
          </h1>
          <Row className="about-row g-5 mb-5 d-flex justify-content-center">
            {usTeam.map((usMember, i) => (
              <Col
                key={i}
                xs={12} // cards per row on extra small screens
                md={6} // cards per row on medium screens
                lg={4} // cards per row on large screens
                className="d-flex justify-content-center"
              >
                <Card className="team-card d-flex align-items-center">
                  <img
                    src={require(`../../assets/Team/${usMember.memberImg}.webp`)}
                    alt="logo"
                    className="team-img mt-5"
                  />
                  <section className="d-flex flex-row justify-content-between align-items-center w-100 mt-4 mb-5 text-light text-start px-4 gap-1">
                    <div>
                      <h6 className="fw-normal mb-0">{usMember.memberName}</h6>
                      <p className="title-p mt-0 mb-0 text-start">{usMember.memberTitle}</p>
                    </div>
                    <div>
                      <a href={usMember.memberLinkedIn} className="text-light">
                        <FontAwesomeIcon icon={faLinkedin} className="fs-1"/>
                      </a>
                    </div>
                  </section>
                </Card>

              </Col>
            ))}
          </Row>
        </section>
      </div>
      {/* PH TEAM */}
      <div>
        <section className="ff-positions d-flex flex-column justify-content-center align-items-center">
          <h1 className="text-light fw-bold mb-5 my-5">
            PH TEAM <span style={{ color: "#92C9FF" }}>MEMBERS</span>
          </h1>
          <Row className="about-row g-5 mb-5 d-flex justify-content-center">
            {phTeam.map((phMember, i) => (
              <Col
                key={i}
                xs={12} // cards per row on extra small screens
                md={6} // cards per row on medium screens
                lg={4} // cards per row on large screens
                className="d-flex justify-content-center"
              >
                <Card className="team-card d-flex align-items-center my-4">
                  <img
                    src={require(`../../assets/Team/${phMember.memberImg}.webp`)}
                    alt="logo"
                    className="team-img mt-5"
                  />
                  <section className="d-flex flex-row justify-content-between align-items-center w-100 mt-4 mb-5 text-light text-start px-4 gap-1">
                    <div>
                      <h6 className="fw-normal mb-0">{phMember.memberName}</h6>
                      <p className="title-p mt-0 mb-0 text-start">{phMember.memberTitle}</p>
                    </div>
                    <div>
                      <a href={phMember.memberLinkedIn} className="text-light">
                        <FontAwesomeIcon icon={faLinkedin} className="fs-1" />
                      </a>
                    </div>
                  </section>
                </Card>

              </Col>
            ))}
          </Row>
        </section>
      </div>

      {/* BoardAdvisors */}
      <div>
        <section className="ff-positions d-flex flex-column justify-content-center align-items-center">
          <h1 className="text-light fw-bold mb-5 my-5">
            BOARD <span style={{ color: "#92C9FF" }}>ADVISORS</span>
          </h1>
          <Row className="about-row g-5 mb-5 d-flex justify-content-center">
            {boardAdvisors.map((baMember, i) => (
              <Col
                key={i}
                xs={12} // cards per row on extra small screens
                md={6} // cards per row on medium screens
                lg={4} // cards per row on large screens
                className="d-flex justify-content-center"
              >
                <Card className="team-card d-flex align-items-center my-4">
                  <img
                    src={require(`../../assets/Team/${baMember.memberImg}.webp`)}
                    alt="logo"
                    className="team-img mt-5"
                  />
                  <section className="d-flex flex-row justify-content-between align-items-center w-100 mt-4 mb-5 text-light text-start px-4 gap-1">
                    <div>
                      <h6 className="fw-normal mb-0">{baMember.memberName}</h6>
                      <p className="title-p mt-0 mb-0 text-start">{baMember.memberTitle}</p>
                    </div>
                    <div>
                      <a href={baMember.memberLinkedIn} className="text-light">
                        <FontAwesomeIcon icon={faLinkedin} className="fs-1"/>
                      </a>
                    </div>
                  </section>
                </Card>

              </Col>
            ))}
          </Row>
        </section>
      </div>
      <AboutForm/>
      {/* <Newsletter /> */}
      <Footer />
    </div>
  );
}

export default About;