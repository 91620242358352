import React from "react";
import "./How.css";
import NavigationBar from "../../components/NavBar/Navbar";
import fusioncenter from "../../assets/web.png";
import aipowered from "../../assets/howit.png";
import MeshArchi from "../../components/Cards/MeshArchi/MeshArchi"
import AboutForm from "../../components/AboutForm/AboutForm";
// import Newsletter from "../../components/Newsletter/Newsletter";
import Footer from "../../components/Footer/Footer";
import Cookie from "../../components/Cookie/Cookie";

const How = () => (
  <div className="how">
    <Cookie />
    <NavigationBar />
    <section className="how-body" id="how-body">
    </section>
    <section className="fusion-center" style={{ backgroundColor: "#EFF7FF" }}>
      {" "}
      {/* Fusion Center */}
      <div className="fusion-container">
        <h1 className="how-title fw-bold">
          <span style={{ color: "#0167db" }}>FUSIONNODE</span> FUSION CENTER{" "}
          <br /> (NEXT GEN XDR)
        </h1>
      </div>
      <p className="ai-p fs-6 text-center">
        Built upon an AI-powered Mesh Architecture framework, the FusionNode Platform harmonizes with our Elite Service Delivery Model to become an integral part of your team. We offer 24/7 monitoring, detection, and responses services, coupled with ongoing risk management and proactive security awareness training. Our goal is to fortify your environment proactively with consistently strengthening your security foundation, ensuring peace of mind and robust protection.
      </p>
      <img
        src={fusioncenter}
        alt="FusionNode Fusion Center"
        className="h-75 w-75"
      />
      <h1 className="how-title fw-bold">
        SIMPLE, EFFICIENT,{" "}
        <span style={{ color: "#0167db" }}>
          & AI-POWERED
          <br />
          INTELLIGENCE
        </span>
      </h1>
      <img src={aipowered} alt="AI-Powered" className="h-75 w-75" />
    </section>
    <MeshArchi />
    <AboutForm />
    {/* <Newsletter /> */}
    <Footer />
  </div>
);

export default How;
